import React from 'react'
import "../App.css";

const Loading = ({ text, height }) => {
    return (
        <div className="position-relative" style={{ height: height ?? "calc(100vh - 65px)" }}>
            <div className="loading-container">
                <div className="loading" />&nbsp;<span>Loading {text}...</span>
            </div>
        </div>

    )
}

export default Loading