import { Link } from "react-router-dom";
import facebookImg from "../../assets/images/facebook-logo.png";
import instagramImg from "../../assets/images/instagram.png";
import twitterImg from "../../assets/images/Twitter-Logo.png";
import { useLocation } from "react-router-dom";
import "../../Styles/Footer.css";

export const Footer = () => {
  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const location = useLocation();
  const page = location.pathname;

  return (
    <div>
      <div className="container-fluid p-0" apple="footer">
        <div className="horizontal-line"></div>
        <div className="footerBox">
          <div>
            <p className="footerBoxTitle">Useful Links</p>
            <ul>
              <li>
                <Link
                  to="/"
                  onClick={ScrollToTop}
                  id={page === "/" ? "currentSectionFooter" : ""}
                  className="footerLink"
                  aria-current="page"
                >
                  {" "}
                  Home{" "}
                </Link>{" "}
              </li>
              <li>
                <Link
                  to="/products"
                  onClick={ScrollToTop}
                  id={page === "/products" ? "currentSectionFooter" : ""}
                  className="footerLink"
                  aria-current="page"
                >
                  {" "}
                  Product{" "}
                </Link>{" "}
              </li>
              <li>
                <Link
                  to="/artists"
                  onClick={ScrollToTop}
                  id={page === "/artists" ? "currentSectionFooter" : ""}
                  className="footerLink"
                  aria-current="page"
                >
                  {" "}
                  Artist{" "}
                </Link>{" "}
              </li>
              <li>
                <Link
                  to="/gallery"
                  onClick={ScrollToTop}
                  id={page === "/gallery" ? "currentSectionFooter" : ""}
                  className="footerLink"
                  aria-current="page"
                >
                  {" "}
                  Gallery{" "}
                </Link>{" "}
              </li>
            </ul>
          </div>
          <div>
            <p className="footerBoxTitle">Our Services</p>
            <p>Accounting Service and Advisory</p>
            <p>Audit Assurance and Reporting</p>
            <p>Tax Management Services</p>
            <p>Payroll Management Services</p>
          </div>
          <div>
            <h3 className="footerBoxTitle">Our Social Network</h3>
            <p>Cras fermentum odio eu</p>
            <p>feugiat lide par naso tierra</p>
            <p>videa magna</p>
            <div className="footerIcon">
              <div>
                <a href="https://www.facebook.com/">
                  <img src={facebookImg} alt="facebook" className="footerImg" />
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/">
                  <img src={instagramImg} alt="" className="footerImg" />
                </a>
              </div>
              <div>
                <a href="https://twitter.com/">
                  <img
                    src={twitterImg}
                    alt=""
                    className="footerImg"
                    style={{ width: "30px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-dark copyrightBox d-flex justify-content-between py-2">
          <p className="text-white d-flex justify-content-center align-items-center p-2 mb-0 copyright">
            © Copyright 2012 - {new Date().getFullYear()}. | All Rights Reserved
            | Powered by
          </p>
          <p className="text-white d-flex align-items-center mb-0">V-0.01</p>
        </div>
      </div>
    </div>
  );
};
