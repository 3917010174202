import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import RoutePath from './routes/route'


function App() {
  return (
    <div className="App">
      <RoutePath />
    </div>
  )
}

export default App;
