import thirdRowImg from '../../assets/images/repair.png';

export const ThirdRow = () => {
    return (
        <div>
            <div className="container-fluid p-0">
                <div className="thirdRow">
                    <div className="thirdRowBox">
                        <p className="thirdRowBoxTitle">Repair</p>
                        <div className="homeUnderline"></div>
                        <p className="thirdRowBoxText">
                            They bring us together.
                        </p>
                        <p className="thirdRowBoxText">
                             Athletes inspire us.
                        </p>
                        <p className="thirdRowBoxText">
                            They help us to get up and get moving.
                        </p>
                    </div>
                    <div className='thirdRowBoxImg'>
                        <img className="thirdRowImg" src={thirdRowImg} alt="thirdRowImg" />
                    </div>
                </div>
              

            </div>
        </div>
    )
}