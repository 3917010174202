import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HomePage } from '../pages/home/HomePage';
import { NavLink } from '../layouts/mainlayout/NavLink';
import { Footer } from '../layouts/mainlayout/Footer';
import { Suspense } from 'react';
import Loading from '../components/Loading';

const ProductPage = React.lazy(() => import('../pages/product/List/ProductList'));
const ProductDetailedPage = React.lazy(() => import('../pages/product/Detail/ProductDetail'));
const ArtistPage = React.lazy(() => import('../pages/artist/ArtistList/ArtistList'));
const ArtistDetailedPage = React.lazy(() => import("../pages/artist/ArtistDetail/ArtistProfilePage"));
const GalleryInfoPage = React.lazy(() => import('../pages/gallery/GalleryPage'));


const RoutePath = () => {
  return (
    <div className="route">
      <NavLink />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="products" element={
          <Suspense fallback={<Loading text="Product List" />}>
            <ProductPage />
          </Suspense>
        } />
        <Route path="artists" element={
          <Suspense fallback={<Loading text="Artist List" />}>
            <ArtistPage />
          </Suspense>
        } />
        <Route path="gallery" element={
          <Suspense fallback={<Loading text="Gallery List" />}>
            <GalleryInfoPage />
          </Suspense>
        } />
        <Route path="products/:id/detail" element={
          <Suspense fallback={<Loading text="Product Detailed Information" />}>
            <ProductDetailedPage />
          </Suspense>
        } />
        <Route path="artists/:id/profile" element={
          <Suspense fallback={<Loading text="Artist Detailed Information" />}>
            <ArtistDetailedPage />
          </Suspense>

        } />
      </Routes>
      <Footer />
    </div>
  )
}

export default RoutePath


