import { FiMapPin } from 'react-icons/fi';
import { ImMail } from 'react-icons/im';
import { BsTelephoneFill } from 'react-icons/bs';

export const Contact = () => {
    return (
        <div>
            <div className="container-fluid contactBox">
                <p className="contactBoxTitle">Contact</p>
                <div className="contact-line">
                    <div className="line-sm"></div>
                    <div className="line-lg"></div>
                    <div className="line-sm"></div>
                </div>
                <div className="contact">
                    <ul className="p-0">
                        <li>
                            <ul>
                                <li><FiMapPin color='#E1593D' size='25px' /></li>
                                <li>
                                    <p className="contactBoxText"> No.9, Jasmin Rd, New Bagan, Mandalay</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li><ImMail color='#E1593D' size='25px' /></li>
                                <li>
                                    <p className="contactBoxText"> mamawbaganhouse@gmail.com</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li><BsTelephoneFill color='#E1593D' size='25px' /></li>
                                <li>
                                    <p className="contactBoxText">+95-09961010</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div>
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1202.761908488465!2d96.1285625528927!3d16.84962622051823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c194eb70b00001%3A0xb2a1f5694971b1b6!2sMICT%20Park%20Main%20Building!5e0!3m2!1sen!2smm!4v1636343027209!5m2!1sen!2smm" 
                        title="MapLocation" className='mapImg'
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}