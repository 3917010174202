import React, { useState } from "react";
import { Link } from "react-router-dom";
import adaLogo from "../../assets/images/ADA-logo.png";
import { FaSistrix, FaUserAlt, FaShoppingCart, FaChevronUp } from 'react-icons/fa';
import "../../Styles/NavLink.css";
import { useLocation } from 'react-router-dom'

export const NavLink = () => {
    const [toggle, setToggle] = useState({
        btnClass: "navbar-toggler collapsed",
        navClass: "navbar-collapse collapse",
        fakeOutClass: ""
    });
    const location = useLocation();
    const page = location.pathname;


    if (page === '/') {
        document.title = 'Home'.toUpperCase();
    }
    else {
        const longTitle = page.split("/")
        document.title = (longTitle.length === 3) ?
            (longTitle[2].toUpperCase()) :
            (longTitle[1].toUpperCase())
    }

    const ScrollToTop = () => {
        window.scrollTo(0, 0);
    }
    const btnClick = () => {
        setToggle({
            btnClass: "navbar-toggler ",
            navClass: "navbar-collapse collapse show",
            fakeOutClass: ""
        });
    }
    const navClick = () => {
        window.scrollTo(0, 0);
        setToggle({
            btnClass: "navbar-toggler collapsed",
            navClass: "navbar-collapse collapse",
            fakeOutClass: " "
        });
    }
    return (
        <div>
            <div className="container-fluid p-0 header position-fixed" style={{ zIndex: '20' }}>
                <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
                    <div className="container-fluid p-0">
                        <a className="navbar-brand" href="/">
                            <img className="adaLogo" src={adaLogo} alt="ada_logo" />
                        </a>
                        <button className={toggle.btnClass} type="button" onClick={btnClick} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa fa-bars menu-icon"></i>
                        </button>
                        <div className={toggle.navClass} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0" id={toggle.fakeOutClass}>
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" onClick={navClick} id={page === '/' ? 'currentSection' : ''} aria-current="page"> Home </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/products" className="nav-link" onClick={navClick} id={page === '/products' ? 'currentSection' : ''} aria-current="page"> Product </Link>
                                    <ul>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/artists" className="nav-link" onClick={navClick} id={page === '/artists' ? 'currentSection' : ''} aria-current="page"> Artist </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/gallery" className="nav-link" onClick={navClick} id={page === '/gallery' ? 'currentSection' : ''} aria-current="page"> Gallery </Link>
                                </li>
                            </ul>
                            <ul className="navbarIconBox">
                                <li><FaSistrix color='black' /></li>
                                <li><FaUserAlt color='black' /></li>
                                <li><FaShoppingCart color='black' /></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="container-fluid toTopIcon">
                <div className="toTop">
                    <button onClick={ScrollToTop} className="scroll-to-next bg-white shadow">
                        <FaChevronUp color="#E1593D" size='25px' />
                    </button>
                </div>
            </div>
        </div>
    )
}
