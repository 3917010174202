import secondRowImg from '../../assets/images/buyback.png';

export const SecondRow = () => {
    return (
        <div>
            <div className="container-fluid p-0">
                <div className="secondRow">
                    <img className="secondRowImg" src={secondRowImg} alt="secondRowImg" />
                    <div className="secondRowBox">
                        <p className="secondRowBoxTitle">Buyback</p>
                        <p className="secondRowBoxText">
                            They bring us together. Athletes inspire for  inspire us. 
                            The help us to and get moving.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}