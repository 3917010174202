import "./HomePage.css";
import { Contact } from "../../components/Home/Contact";
import { FirstRow } from "../../components/Home/FirstRow";
import { FourRow } from "../../components/Home/FourRow";
import { SecondRow } from "../../components/Home/SecondRow";
import { ThirdRow } from "../../components/Home/HomeThirdRow";

export const HomePage = () =>{
    return (
        <div>
            <div id="home">
                <FirstRow />
                <SecondRow />
                <ThirdRow />
                <FourRow />
                <Contact />
            </div>
        </div>
    )
}