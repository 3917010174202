import firstRowImg from '../../assets/images/bagan-sunset.png';

export const FirstRow = () => {
    return (
        <div>
            <div className="container-fluid p-0">
                <div className="firstRow">
                    <img className="firstRowImg" src={firstRowImg} alt="firstRowImg" />
                    <div className="firstRowBox">
                        <p className="firstRowBoxTitle">MYANMAR</p>
                        <p className="firstRowBoxText">Authentic Lacquerware Shop</p>
                    </div>
                </div>
            </div>
        </div>
    )
}