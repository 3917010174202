import fourRowImg from '../../assets/images/sale-gallery.png';

export const FourRow = () => {
    return (
        <div>
            <div className="container-fluid p-0">
                <div className="fourRow">
                    <img className="fourRowImg" src={fourRowImg} alt="fourRowImg" />
                    <div className="fourRowBox">
                        <p className="fourRowBoxTitle">MYANMAR</p>
                        <p className="fourRowBoxText">They bring us together. Athletes inspire us.</p>
                        <p className="fourRowBoxText">They help us to get up and get moving.</p>
                        <p className="fourRowBoxText">They help us to get up and get moving.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}